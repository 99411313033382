.navbarMainTag {
  background-color: transparent;
}

.logo {
  font-family: "Sacramento", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 35px;
}
