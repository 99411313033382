.footer {
  background-color: black;
  color: white;
}

.titleFooter {
  font-family: "Urbanist", sans-serif;
  font-size: 4vh;
  font-weight: 700;
  color: white;
  text-align: center;
}

.footerContact > p {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: white;
}

.footerContact > h5 {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: white;
}

.hours {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: white;
}

.CodeSignAttr {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 2.5vh;
}
