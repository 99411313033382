.about {
  scroll-margin-top: 62px;
  max-width: 70%;
  padding-top: 140px;
  padding-bottom: 140px;
}

.aboutParent {
  background-color: white;
}

.aboutTitle {
  font-family: "Urbanist", sans-serif;
  font-size: 4vh;
  font-weight: 700;
  color: black;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .about {
    scroll-margin-top: 57px;
    max-width: 90%;
  }

  .aboutTitle {
    text-align: left;
  }
}

.aboutText {
  font-family: "Urbanist", sans-serif;
  font-size: 3vh;
  font-weight: 400;
  color: black;
}
