.reviews {
  background-color: #e3bfac;
}

.reviewTile {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .reviewTile {
    width: 100%;
  }
}
