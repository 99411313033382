.services {
  scroll-margin-top: 62px;
  padding-top: 140px;
  padding-bottom: 140px;
}

@media screen and (max-width: 992px) {
  .services {
    scroll-margin-top: 57px;
  }
}

.servicesTitle {
  font-family: "Urbanist", sans-serif;
  font-size: 4vh;
  font-weight: 700;
  color: black;
  text-align: center;
  padding-bottom: 40px;
}

.services .servicesList {
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  color: black;
}
