.landing {
  height: 100vh;
  background-image: url("./Images/bg2.jpg");
  background-size: cover;
  background-position: 0px 50%;
}

.landingText {
  padding-left: 5vh;
  padding-top: 55vh;
  animation: initAnimation 2s ease-in-out;
}

.landingImg {
  padding-top: 100px;
  animation: initAnimation 2s ease-in-out;
}

.title {
  color: #5a3d3d;
  font-size: 15vh;
  font-family: "Sacramento", sans-serif;
  font-style: italic;
  font-weight: 500;
}

.bio {
  font-size: 4vh;
  font-family: "Urbanist", sans-serif;
  color: white;
}

@media screen and (max-width: 992px) {
  .landingButton {
    width: 90vw;
  }

  .landingText {
    padding-left: 0;
    padding-top: 10vh;
  }

  .landingImg {
    padding-top: 15vh;
  }

  .title {
    font-size: 12vh;
  }

  .landing {
    height: 100vh;
    background-image: url("./Images/bg4.jpg");
    background-size: cover;
    background-position: 0px 50%;
  }
}

@keyframes initAnimation {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}
